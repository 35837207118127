/**
 * Implementation of the subset operation in set theory, which
 * checks if set A is a subset of set B. A subset means all elements
 * of set A are elements of B.
 *  - **Formal definition**: `A ⊆ B`
 *  - This method runs in *linear time*, or `O(n)`, where `n = |A|`
 *    (size of set A).
 *
 * @see [`Set.prototype.isSubsetOf`](https://tc39.es/proposal-set-methods/#sec-set.prototype.issubsetof)
 * @example
 * ```ts
 * // {1} ⊂ {1, 3}
 * isSubsetOf(new Set([1]), new Set([1, 3])); // true
 * ```
 * @param a - Set A of type `<TElement>` elements
 * @param b - Set B of type `<TElement>` elements
 * @returns Whether `A ⊆ B` is true
 */
export function isSubsetOf<TElement>(a: Set<TElement>, b: Set<TElement>): boolean {
  return a.size <= b.size && containsAllElements(b, a)
}

/**
 * Checks if all elements of set B are contained within
 * set B.
 *  - This method runs in bilinear time on average, or `O(n * m)`, where
 *    `n = |A|` (size of set A), and `m = |B|` (size of set B).
 *
 * @param a - Set A of type `<TElement>` elements
 * @param b - Set B of type `<TElement>` elements
 * @returns Whether A contains all elements of B
 */
export function containsAllElements<TElement>(a: Set<TElement>, b: Set<TElement>): boolean {
  for (const element of b) {
    if (!a.has(element)) {
      return false
    }
  }

  return true
}
