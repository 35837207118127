import { secondaryColor } from '@orus.eu/pharaoh'

type OrusIconSvgProps = {
  widthPx?: number
  fillColor?: string
}

export function OrusIconSvg({ widthPx = 108, fillColor = secondaryColor }: OrusIconSvgProps): JSX.Element {
  const scale = widthPx / 108
  const height = 120 * scale
  return (
    <svg width={widthPx} height={height} viewBox="0 0 108 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.5377 27.8936C10.3137 45.3403 1.16948 73.3846 0.105151 112.027C-0.0130494 116.318 3.46929 119.8 7.74887 119.8H47.3266C51.6062 119.8 55.0567 116.313 55.2095 112.023C55.9806 90.3699 60.0778 74.8431 67.501 65.4428C74.7639 56.284 85.6165 51.0347 100.059 49.6947C104.32 49.2993 107.8 45.8701 107.8 41.577V7.77322C107.8 3.48015 104.324 -0.0193843 100.048 0.156236C68.692 1.44405 44.522 10.6898 27.5377 27.8936Z"
        fill={fillColor}
      />
    </svg>
  )
}
